<template lang="pug">
main(v-if='course', data-view)
  h2 Les 3 premières vidéos du programme gratuites
  prismic-rich-text(:field='course.data.body[0].primary.video_text')
  .videos-wrapper 
    prismic-embed.videos-wrapper__video(
      ref="ytVideo"
      v-for='video in courseFreeVideosSlice.primary',
      :key='video.title',
      :field='video'
    )
  .buttons
    //- common-button.mr-2.mb-2(
    //-   label='Voir la fiche du programme',
    //-   variant='primary',
    //-   :path='{ name: "CourseItem", params: { uid: $route.params.uid } }'
    //- )
    common-button.mb-2(
      label='Acheter le programme',
      variant='orange',
      @click='addCourseToCard',
      isButton
    )
</template>

<script>
export default {
  name: 'CourseFreeVideos',
  components: {
    CommonButton: () => import('@/components/CommonButton')
  },
  data: () => {
    return {
      course: null
    }
  },
  computed: {
    courseFreeVideosSlice() {
      return this.course.data.body.find((slice) => slice.slice_type === 'free_videos')
    }
  },
  created() {
    this.getCourseByUid()
  },
  methods: {
    async getCourseByUid() {
      const result = await this.$prismic.client.getByUID('course', this.$route.params.uid)
      this.course = result
    },
    addCourseToCard() {
      this.$store.commit('ADD_ITEM_TO_CARD', this.course)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
@import '@/styles/mixins.scss';

h2 {
  font-weight: bold;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: $lightBlue;
  margin-bottom: 2rem;
}

.videos-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 3rem 0;

  &__video {
    width: 300px;
    height: 200px;
    margin-bottom: 1rem;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    &::v-deep {
      iframe {
        width: 280px;
        height: 170px;
      }

      @include medium-screen {
        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

::v-deep {
  .ytp-cued-thumbnail-overlay-image {
    background-position: left !important;
  }
}
</style>
